import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import { h3Styles } from "@constants/typography"
import { Close } from "@components/icons"
import { DesktopOnly } from "@utils/MediaQuery"

const Container = styled.div<{ isOpen?: boolean }>`
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  will-change: opacity, transform;
  transition: opacity 200ms ${cubicBezier};
  overflow-y: auto;
  padding: 88px calc(1 / 12 * 100vw) 0;
  background: ${colors.offWhite};
  pointer-events: ${(props) => (props.isOpen ? "" : "none")};
  cursor: default;
`

const Content = styled.div<{ isOpen?: boolean }>`
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  will-change: opacity;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition: opacity 300ms ${cubicBezier} 50ms;
  height: 100%;
`

const Menu = styled.div`
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  display: flex;
`

const linkStyles = `
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  overflow: hidden;
`

const InternalLink = styled(Link)`
  ${linkStyles}
`

const ExternalLink = styled.a`
  ${linkStyles}
`

const Text = styled.div`
  ${h3Styles}
  will-change: transform;
  transform: translateX(64px);
  transition: transform ${animation.medium} ${cubicBezier};
  padding: 32px 0;
  background: ${colors.offWhite};

  @media ${breakpoints.laptop} {
    ${InternalLink}:hover & {
      transform: translateX(0);
    }
    ${ExternalLink}:hover & {
      transform: translateX(0);
    }
  }
`

const Dash = styled.div`
  opacity: 0;
  will-change: transform;
  transform: translateX(64px);
  transition: opacity ${animation.medium} ${cubicBezier} 50ms,
    transform ${animation.medium} ${cubicBezier} 50ms;
  width: 30px;
  height: 1px;
  margin-left: 34px;
  background: ${colors.black};

  @media ${breakpoints.laptop} {
    ${InternalLink}:hover & {
      opacity: 1;
      transform: translateX(0);
    }
    ${ExternalLink}:hover & {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

const Button = styled.button`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 56px;
  height: 56px;
  padding: 8px;
  margin-bottom: 64px;
  background: ${colors.dividerLineGray};
  border: none;
  border-radius: 2px;
  cursor: pointer;

  :focus {
    outline: none;
  }
`

interface DesktopMenuProps {
  isOpen?: boolean
  onRequestClose?: () => void
}

const DesktopMenu = (props: DesktopMenuProps) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27 && props.onRequestClose) {
        props.onRequestClose()
      }
    }
    window.addEventListener("keydown", handleEsc)

    return () => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [])

  return (
    <DesktopOnly>
      <Container isOpen={props.isOpen}>
        <Content isOpen={props.isOpen}>
          <Button onClick={props.onRequestClose}>
            <Close />
          </Button>
          <Menu>
            <InternalLink to="/" onClick={props.onRequestClose}>
              <Text>Home</Text>
              <Dash />
            </InternalLink>
            <ExternalLink href="https://urbanarmorgear.com">
              <Text>Store</Text>
              <Dash />
            </ExternalLink>
            <ExternalLink href="https://news.urbanarmorgear.com">
              <Text>News</Text>
              <Dash />
            </ExternalLink>
            <ExternalLink href="https://enterprise.urbanarmorgear.com">
              <Text>Enterprise</Text>
              <Dash />
            </ExternalLink>
          </Menu>
        </Content>
      </Container>
    </DesktopOnly>
  )
}

export default DesktopMenu
