import React from "react"
import styled from "styled-components"
import breakpoints from "@constants/breakpoints"

export const MobileOnlyContainer = styled.div`
  @media ${breakpoints.tablet} {
    display: none;
  }
  @media ${breakpoints.laptop} {
    display: none;
  }
`

export const MobileAndTabletContainer = styled.div`
  @media ${breakpoints.laptop} {
    display: none;
  }
`

export const MobileAndDesktopContainer = styled.div`
  @media ${breakpoints.tablet} {
    display: none;
  }
`

export const TabletOnlyContainer = styled.div`
  display: none;
  @media ${breakpoints.tablet} {
    display: initial;
  }
`

export const TabletAndDesktopContainer = styled.div`
  display: none;
  @media ${breakpoints.tablet} {
    display: initial;
  }
  @media ${breakpoints.laptop} {
    display: initial;
  }
`

export const DesktopOnlyContainer = styled.div`
  display: none;
  @media ${breakpoints.laptop} {
    display: initial;
  }
`

interface BaseProps {
  children?: React.ReactNode
}

export const MobileOnly: React.FC<BaseProps> = ({ children }) => {
  return <MobileOnlyContainer>{children}</MobileOnlyContainer>
}

export const MobileAndTabletOnly: React.FC<BaseProps> = ({ children }) => {
  return <MobileAndTabletContainer>{children}</MobileAndTabletContainer>
}

export const MobileAndDesktopOnly: React.FC<BaseProps> = ({ children }) => {
  return <MobileAndDesktopContainer>{children}</MobileAndDesktopContainer>
}

export const TabletOnly: React.FC<BaseProps> = ({ children }) => {
  return <TabletOnlyContainer>{children}</TabletOnlyContainer>
}

export const TabletAndDesktopOnly: React.FC<BaseProps> = ({ children }) => {
  return <TabletAndDesktopContainer>{children}</TabletAndDesktopContainer>
}

export const DesktopOnly: React.FC<BaseProps> = ({ children }) => {
  return <DesktopOnlyContainer>{children}</DesktopOnlyContainer>
}
