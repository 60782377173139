import React from "react"
import DesktopHeader from "./DesktopHeader"
import MobileHeader from "./MobileHeader"
import { DesktopOnly, MobileAndTabletOnly } from "@utils/MediaQuery"

interface HeaderProps {
  onClickMenu?: () => void
  shortenDesktopHeader?: boolean
}

const Header = (props: HeaderProps) => (
  <>
    <DesktopOnly>
      <DesktopHeader {...props} />
    </DesktopOnly>
    <MobileAndTabletOnly>
      <MobileHeader {...props} />
    </MobileAndTabletOnly>
  </>
)

export default Header
