import React from "react"
import { throttle } from "lodash"

const useScrollHandler = ({
  onScroll,
  onResize,
  onCleanUp,
  runForProps,
}: {
  onScroll: () => void
  onResize?: () => void
  onCleanUp?: () => void
  runForProps?: Array<any>
}) => {
  const onScrollHandler = () => {
    typeof window !== "undefined"
      ? window.requestAnimationFrame(onScroll)
      : onScroll
  }
  const onResizeThrottled = onResize ? throttle(onResize, 100) : undefined

  React.useEffect(
    (): any => {
      if (onResize && onResizeThrottled) {
        window.addEventListener("resize", onResizeThrottled)
      }

      document.addEventListener("scroll", onScrollHandler)

      return () => {
        if (onResize && onResizeThrottled) {
          window.removeEventListener("resize", onResizeThrottled)
        }

        document.removeEventListener("scroll", onScrollHandler)
        onCleanUp && onCleanUp()
      }
    },
    runForProps ? runForProps : []
  )
}

export default useScrollHandler
