import React from "react"
import styled from "styled-components"
import breakpoints from "@constants/breakpoints"
import Header from "@components/Header"
import Footer from "@components/Footer"
import Menu from "@components/Menu"
import useScrollHandler from "@hooks/useScrollHandler"

const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 56px);

  @media ${breakpoints.laptop} {
    min-height: calc(100vh - 88px);
    padding-bottom: 316px;
  }
`

const Main = styled.main`
  margin-top: 56px;

  @media ${breakpoints.tablet} {
    margin-top: 72px;
  }

  @media ${breakpoints.laptop} {
    margin-top: 88px;
  }
`

interface LayoutProps {
  children?: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const [menu, setMenu] = React.useState(false)

  const openMenu = () => {
    setMenu(true)
  }

  const closeMenu = () => {
    setMenu(false)
  }

  const [shortenDesktopHeader, setShortenDesktopHeader] = React.useState(false)

  const handleShortenHeight = () => {
    const html = document.getElementsByTagName("html")
    if (html && html[0] && html[0].getBoundingClientRect().top < -50) {
      setShortenDesktopHeader(true)
    } else {
      setShortenDesktopHeader(false)
    }
  }

  const scrollHandler = () => {
    handleShortenHeight()
  }

  useScrollHandler({
    onScroll: scrollHandler,
    runForProps: [],
  })

  return (
    <Container>
      <Header
        onClickMenu={() => openMenu()}
        shortenDesktopHeader={shortenDesktopHeader}
      />
      <Main id="mainContent">{props.children}</Main>
      <Footer />
      <Menu isOpen={menu} onRequestClose={closeMenu} />
    </Container>
  )
}

export default Layout
