import React from "react"
import styled, { css } from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import { Menu } from "@components/icons"
import Logo from "@components/Logo"
import Tabs from "./Tabs"

const Background = styled.div<{
  shorten?: boolean
}>`
  ${({ shorten }) => css`
    z-index: 999;
    position: fixed;
    top: 0;
    will-change: transform;
    transform-origin: top;
    transform: ${shorten
      ? "scaleY(calc(64 / 88)) translateZ(0)"
      : "scaleY(1) translateZ(0)"};
    transition: transform ${animation.quick} ${cubicBezier};
    width: 100%;
    height: 88px;
    background-color: ${colors.offWhite};
  `}
`

const paddingRight = "0.5 / 12 * 100vw - 38px"
const Container = styled.div<{
  shorten?: boolean
}>`
  ${({ shorten }) => css`
    z-index: 1000;
    position: fixed;
    top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    display: flex;
    transform-origin: top;
    transition: height ${animation.quick} ${cubicBezier};
    will-change: height;
    width: 100%;
    height: ${shorten ? "64px" : "88px"};
    padding: 0 calc(${paddingRight}) 0
      calc(${paddingRight} + (0.25 / 12 * 100vw));
    border-bottom: ${shorten ? `solid 1px ${colors.dividerLineGray}` : "none"};
  `}
`

// const Content = styled.div`
//   flex-direction: row;
//   flex-grow: 1;
//   align-items: center;
//   justify-content: flex-end;
//   display: flex;
//   overflow: hidden;
//   height: 100%;
//   margin-left: calc(0.25 / 12 * 100vw);
// `

const Button = styled.button`
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  will-change: background-color;
  transition: background-color ${animation.medium} ${cubicBezier};
  height: 100%;
  padding: 0 calc(0.25 / 12 * 100vw);
  border: none;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media ${breakpoints.largeDesktop} {
    width: 88px;
    padding: 0;

    &:hover {
      background-color: ${colors.dividerLineGray};
    }
  }
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
`

export interface DesktopHeaderProps {
  onClickMenu?: () => void
  shortenDesktopHeader?: boolean
}

const DesktopHeader = (props: DesktopHeaderProps) => {
  return (
    <>
      <Background shorten={props.shortenDesktopHeader} />
      <Container id="desktopHeader" shorten={props.shortenDesktopHeader}>
        <Logo />
        <Tabs />
        <Button onClick={props.onClickMenu}>
          <Icon>
            <Menu />
          </Icon>
        </Button>
      </Container>
    </>
  )
}

export default DesktopHeader
