import styled, { css } from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"

const activeProps = css`
  visibility: visible;
  transition: background-color ${animation.quick} ${cubicBezier},
    visibility 0ms linear;
  background-color: ${colors.black50};
`

export default styled.div<{ isOpen?: boolean; topOffset?: number }>`
  ${({ isOpen, topOffset }) => css`
    z-index: 1001;
    position: fixed;
    top: ${topOffset || 0}px;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    will-change: background-color;
    transition: background-color ${animation.quick} ${cubicBezier},
      visibility 0ms linear ${animation.medium};
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    cursor: pointer;

    ${isOpen ? activeProps : ""}
  `}
`
