import React from "react"
import * as R from "ramda"

const useModalBackgroundClose = ({
  modalRef,
  excludeRefs,
  isOpen,
  onRequestClose,
}: any) => {
  const handleClick = (event: any) => {
    let preventClose = false

    const body = document.getElementsByTagName("body")[0]

    if (!body.contains(event.target) && onRequestClose) {
      preventClose = true
    }

    if (!preventClose && onRequestClose && excludeRefs) {
      excludeRefs.forEach((ref) => {
        if (ref.current.contains(event.target)) {
          preventClose = true
        }
      })
    }

    if (
      !preventClose &&
      onRequestClose &&
      modalRef &&
      !modalRef.current.contains(event.target)
    ) {
      onRequestClose()
    }
  }

  const hasOpenModal = (className) =>
    className && R.includes("modalClassRef", className)

  React.useEffect((): any => {
    if (modalRef && isOpen) {
      const body = document.getElementsByTagName("body")[0]
      const [refClass] = modalRef.current.classList
      const refLabel = `modalClassRef-${refClass}`

      body.classList.add("noScroll")
      body.classList.add(refLabel)
      document.addEventListener("click", handleClick)

      return () => {
        document.removeEventListener("click", handleClick)
        body.classList.remove(refLabel)

        if (R.filter(hasOpenModal, body.classList).length === 0) {
          body.classList.remove("noScroll")
        }
      }
    }
  }, [isOpen, modalRef, excludeRefs])
}

export default useModalBackgroundClose
