import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import {
  smallcapsBold13Styles,
  smallcapsRegular13Styles,
} from "@constants/typography"
import {
  UAGLogo,
  Facebook,
  Instagram,
  Twitter,
  Youtube,
} from "@components/icons"

export interface FooterLinkProps {
  link?: string
  text: string
  external?: boolean
  openNewTab?: boolean
}

export const Container = styled.footer`
  position: absolute;
  bottom: 0;
  flex-shrink: 0;
  width: 100%;
  padding: 72px 32px 49px;
  background-color: ${colors.black};

  @media ${breakpoints.tablet} {
    flex-direction: column;
    display: flex;
    height: auto;
    padding: 124px calc(1 / 12 * 100vw) 74px;
  }

  @media ${breakpoints.laptop} {
    flex-direction: column;
    display: flex;
    padding: 93px calc(1 / 12 * 100vw) 95px;
  }
`

const FooterLogoWrapper = styled.div`
  @media ${breakpoints.largerThanMobile} {
    flex: 1;
    align-self: center;
  }
`

const FooterTopRow = styled.div`
  flex-direction: column;
  display: flex;

  @media ${breakpoints.largerThanMobile} {
    flex-direction: row;
    margin-bottom: 48px;
  }
`

const FooterBottomRow = styled.div`
  flex-direction: column;
  display: flex;

  @media ${breakpoints.largeLaptop} {
    flex-direction: row;
  }
`

const FooterCopyrightWrapper = styled.div`
  margin-top: 24px;

  @media ${breakpoints.laptop} {
    flex: 1;
  }

  @media ${breakpoints.largeLaptop} {
    margin-top: 0;
  }
`

export const LogoWrapper = styled.div`
  ${smallcapsBold13Styles}
  align-items: center;
  display: flex;
  height: 54px;
  color: ${colors.offWhite};

  @media ${breakpoints.tablet} {
    width: 100%;
    margin-bottom: 6px;
  }
`

export const Logo = styled(Link)`
  display: block;
  width: 59px;
  height: 18px;
  cursor: pointer;
`

export const Icon = styled.div`
  width: 24px;
  height: 24px;
`

export const Social = styled.div`
  display: flex;
  transform: translateX(-12px);
  margin: 32px 0 48px;

  @media ${breakpoints.tablet} {
    transform: translateX(-12px);
    width: calc(7 / 12 * 100vw);
    margin: 76px 0 56px;
  }

  @media ${breakpoints.laptop} {
    align-items: center;
    justify-content: space-between;
    transform: translateX(-14px);
    height: 100%;
    margin: 0;
  }
`

export const SocialIconWrapper = styled.a`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 48px;
  height: 48px;
  margin-right: 24px;

  @media ${breakpoints.tablet} {
    margin-right: 46px;
  }

  @media ${breakpoints.laptop} {
    transition: background-color ${animation.notQuiteLong} ${cubicBezier};
    width: 64px;
    height: 64px;
    margin-right: 0;
    border-radius: 2px;

    &:hover {
      background-color: ${colors.mediumGray10Percent};
    }
  }
`

export const SocialIcon = styled(Icon)`
  cursor: pointer;
  line-height: initial;
`

export const Copyright = styled.div`
  ${smallcapsRegular13Styles}
  font-size: 9px;
  color: ${colors.mediumDarkGray};
  line-height: 9px;

  @media ${breakpoints.tablet} {
    font-size: 11px;
    color: ${colors.copyrightGray};
    line-height: 16px;
  }

  @media ${breakpoints.laptop} {
    font-size: 11px;
    color: ${colors.copyrightGray};
    line-height: 16px;
  }
`

const currentYear = new Date().getFullYear()

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const renderSocialLink = (socialUrl, IconComponent) =>
    socialUrl && (
      <SocialIconWrapper href={socialUrl} target="_blank" rel="noopener">
        <SocialIcon>{IconComponent}</SocialIcon>
      </SocialIconWrapper>
    )

  return (
    <Container>
      <FooterTopRow>
        <FooterLogoWrapper>
          <LogoWrapper>
            <Logo to="/">
              <UAGLogo />
            </Logo>
          </LogoWrapper>
        </FooterLogoWrapper>
        <Social>
          {renderSocialLink(
            "https://www.instagram.com/urbanarmorgear",
            <Instagram />
          )}
          {renderSocialLink(
            "https://www.twitter.com/urbanarmorgear",
            <Twitter />
          )}
          {renderSocialLink(
            "https://www.facebook.com/urbanarmorgear",
            <Facebook />
          )}
          {renderSocialLink(
            "https://www.youtube.com/urbanarmorgear",
            <Youtube />
          )}
        </Social>
      </FooterTopRow>
      <FooterBottomRow>
        <FooterCopyrightWrapper>
          <Copyright>{`© ${currentYear} URBAN ARMOR GEAR, LLC. ALL RIGHTS RESERVED.`}</Copyright>
        </FooterCopyrightWrapper>
      </FooterBottomRow>
    </Container>
  )
}

export default Footer
