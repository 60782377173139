import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import { smallcapsBold14Styles } from "@constants/typography"
import { Chevron } from "@components/icons"

export const Headers = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 86px;
  padding: 0 20px;
  border-bottom: solid 1px ${colors.dividerLineGray};
`

const BreadcrumbContainer = styled.button`
  display: flex;
  transform: translateX(-16px);
  background-color: transparent;
  border: none;
`

const BreadcrumbIcon = styled.div`
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
  margin-right: 6px;
`

export const BreadcrumbText = styled.span`
  ${smallcapsBold14Styles};
  padding-top: 2px;
`

export const BreadcrumbButton: React.FC<{
  label: string
  onClick?: () => void
}> = ({ label, onClick }) => (
  <BreadcrumbContainer onClick={onClick}>
    <BreadcrumbIcon>
      <Chevron />
    </BreadcrumbIcon>
    <BreadcrumbText>{label}</BreadcrumbText>
  </BreadcrumbContainer>
)
