import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import breakpoints from "@constants/breakpoints"
import { Menu } from "@components/icons"
import Logo from "@components/Logo"

const Container = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${colors.offWhite};
`

const Content = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  min-height: 56px;
  padding: 0 0 0 20px;
  border-bottom: solid 1px ${colors.dividerLineGray};

  @media ${breakpoints.tablet} {
    min-height: 72px;
    padding: 0 12px 0 32px;
  }
`

const Button = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 56px;
  height: 100%;
  border: none;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const MenuButton = styled(Button)`
  margin-left: 12px;

  @media ${breakpoints.tablet} {
    margin-left: 42px;
  }
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
`

interface MobileHeaderProps {
  onClickMenu?: () => void
}

const MobileHeader = (props: MobileHeaderProps) => {
  return (
    <Container>
      <Content>
        <Logo />
        <MenuButton onClick={props.onClickMenu}>
          <Icon>
            <Menu />
          </Icon>
        </MenuButton>
      </Content>
    </Container>
  )
}

export default MobileHeader
