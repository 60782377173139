import React from "react"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import Backdrop from "@components/Backdrop"
import Item from "./Item"
import { Headers, BreadcrumbButton } from "./common"
import useModalBackgroundClose from "@hooks/useModalBackgroundClose"
import { MobileAndTabletOnly } from "@utils/MediaQuery"

const openProps = `
  opacity: 1;
  transform: translateX(0);
  transition: opacity ${animation.quick} ${cubicBezier},
    transform ${animation.medium} ${cubicBezier};
`
const Container = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity ${animation.medium} ${cubicBezier},
    transform ${animation.quick} ${cubicBezier};
  will-change: opacity, transform;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-width: calc(100% - 72px);
  background-color: ${colors.offWhite};
  cursor: default;
  ${({ isOpen }) => (isOpen ? openProps : "")}

  @media ${breakpoints.tablet} {
    max-width: 64%;
  }
`

const Items = styled.div`
  overflow: hidden;
`

interface MobileMenuProps {
  isOpen?: boolean
  onRequestClose?: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onRequestClose }) => {
  const menuRef: any = React.useRef(null)

  useModalBackgroundClose({
    modalRef: menuRef,
    isOpen,
    onRequestClose,
  })

  return (
    <MobileAndTabletOnly>
      <Backdrop isOpen={isOpen}>
        <Container id="mobileMenu" ref={menuRef} isOpen={isOpen}>
          <Headers>
            <BreadcrumbButton onClick={onRequestClose} label={"Close"} />
          </Headers>
          <Items>
            <Item link="/" label="Home" />
            <Item link="https://urbanarmorgear.com" label="Store" external />
            <Item
              link="https://news.urbanarmorgear.com"
              label="News"
              external
            />
            <Item
              link="https://enterprise.urbanarmorgear.com"
              label="Enterprise"
              external
            />
          </Items>
        </Container>
      </Backdrop>
    </MobileAndTabletOnly>
  )
}

export default MobileMenu
