import React from "react"
import styled, { CSSObject } from "styled-components"
import { Link } from "gatsby"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import breakpoints from "@constants/breakpoints"
import { smallcapsBold13Styles } from "@constants/typography"

const Container = styled.div`
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  overflow: auto hidden;
  height: 48px;
  padding: 0 20px;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoints.tablet} {
    justify-content: center;
  }

  @media ${breakpoints.laptop} {
    justify-content: center;
    padding: 0;
  }
`

const Tab = styled(Link)`
  ${smallcapsBold13Styles}
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0.3;
  height: 100%;
  margin: 0 10px;
  padding-top: 4px;
  border-bottom: 2px solid transparent;
  color: ${colors.black};

  @media ${breakpoints.tablet} {
    margin: 0 calc(1 / 12 * 100vw);
  }

  @media ${breakpoints.laptop} {
    transition: opacity ${animation.medium} ${cubicBezier},
      border ${animation.medium} ${cubicBezier};
    margin: 0 42px;
    color: ${colors.black};

    &:hover {
      opacity: 1;
      transition: opacity ${animation.quick} ${cubicBezier},
        border ${animation.quick} ${cubicBezier};
    }
  }
`

const Spacer = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 100%;

  @media ${breakpoints.tablet} {
    display: none;
  }
`

const activeStyle: CSSObject = {
  opacity: 1,
  borderBottomColor: colors.black,
}

const Tabs = () => {
  return (
    <Container>
      <Tab to={"/"} activeStyle={activeStyle}>
        All
      </Tab>
      <Tab to={"/tag/storefront"} activeStyle={activeStyle} partiallyActive={true}>
        Storefront
      </Tab>
      <Tab to={"/tag/news"} activeStyle={activeStyle} partiallyActive={true}>
        News
      </Tab>
      <Tab to={"/tag/enterprise"} activeStyle={activeStyle} partiallyActive={true}>
        Enterprise
      </Tab>
      <Spacer />
    </Container>
  )
}

export default Tabs
