import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import colors from "@constants/colors"
import animation, { cubicBezier } from "@constants/animation"
import { body16MainStyles } from "@constants/typography"
import { Chevron } from "@components/icons"

const itemBaseProps = `
  ${body16MainStyles}
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  display: flex;
  transition: background-color ${animation.medium} ${cubicBezier};
  width: 100%;
  height: auto;
  padding: 14px 10px 14px 20px; // padding: 18px 10px 18px 20px;
  color: ${colors.mediumDarkGray};
  background-color: ${colors.offWhite};
  border: none;
  border-bottom: solid 1px ${colors.dividerLineGray};
  cursor: pointer;

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    transition: background-color ${animation.quick} ${cubicBezier};
    background-color: ${colors.dividerLineGray};
    cursor: pointer;
  }
`

export const ItemContainer = styled.div`
  ${itemBaseProps}
`

export const ButtonContainer = styled.button`
  ${itemBaseProps}
`

export const LinkContainer = styled(Link)`
  ${itemBaseProps}
`

export const ExternalLinkContainer = styled.a`
  ${itemBaseProps}
`

export const IconWrapper = styled.div<{ rotate?: number }>`
  flex-shrink: 0;
  transition: transform ${animation.quick} ${cubicBezier};
  width: 24px;
  height: 24px;
  margin-right: 5px;
  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg);`}
`

export interface ItemProps {
  label: string
  link?: string
  external?: boolean
  onClick?: () => void
  alwaysRenderIcon?: boolean
}

const Item: React.FC<ItemProps> = ({
  label,
  link,
  external,
  onClick,
  alwaysRenderIcon,
}) => {
  if (external && link) {
    return (
      <ExternalLinkContainer href={link} target="_blank" rel="noopener">
        {label}
        {alwaysRenderIcon && (
          <IconWrapper>
            <Chevron strokeColor={colors.mediumDarkGray} />
          </IconWrapper>
        )}
      </ExternalLinkContainer>
    )
  } else if (link) {
    return (
      <LinkContainer to={link}>
        {label}
        {alwaysRenderIcon && (
          <IconWrapper>
            <Chevron strokeColor={colors.mediumDarkGray} />
          </IconWrapper>
        )}
      </LinkContainer>
    )
  }

  return (
    <ButtonContainer onClick={onClick}>
      {label}
      <IconWrapper>
        <Chevron strokeColor={colors.mediumDarkGray} />
      </IconWrapper>
    </ButtonContainer>
  )
}

export default Item
